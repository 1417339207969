import React, { Fragment, useContext, useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'

import { AlertContext } from 'contexts/AlertContext'

import ROUTE from 'constants/routes'

const Alert = () => {
    const navigate = useNavigate()
    const t = useTranslate()

    const {
        alert: { message, type, position },
        setAlert,
    } = useContext(AlertContext)

    const notFoundMessage =
        message === t('server.error.invalidCodeForPrivateProject') ||
        message === 'Not Found'

    useEffect(() => {
        notFoundMessage && navigate(ROUTE.NOT_FOUND_PAGE.path)
    }, [message])

    if (!message) return null

    return (
        <Fragment>
            {!notFoundMessage && (
                <span
                    className={`a-alert -${type} -${position}`}
                    onClick={() => setAlert('')}
                >
                    {message}
                </span>
            )}
        </Fragment>
    )
}

export default Alert
