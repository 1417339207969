import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { LAYOUTS } from 'constants/enums'

import { getMenuItems } from './sidebarHelper'

const useMenuItems = (layout) => {
    const { currentUser, isCompanyAdmin } = useContext(CurrentUserContext)
    const { projectId, projectName, securityCode } = useParams()

    const encodedProjectName = encodeURIComponent(projectName)
    const securityCodeParam = securityCode ? `/${securityCode}` : ''

    const MENU_ITEMS = getMenuItems(
        layout,
        projectId,
        encodedProjectName,
        securityCodeParam
    )

    const filteredMenuItems = MENU_ITEMS.filter((item) =>
        item.roles.some((role) => role?.name === currentUser?.role?.name)
    )

    const companyManagementItem = filteredMenuItems.find(
        (item) => item.key === 'company-management'
    )

    if (companyManagementItem && layout === LAYOUTS.ADMIN) {
        companyManagementItem.label = isCompanyAdmin
            ? 'menuItems.companySettings'
            : 'menuItems.companyManagement'
    }


    return layout === LAYOUTS.ADMIN ? filteredMenuItems : MENU_ITEMS
}

useMenuItems.propTypes = {
    layout: PropTypes.oneOf([LAYOUTS.ADMIN, LAYOUTS.AUTH]),
}

export default useMenuItems
