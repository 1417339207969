import React from 'react'
import { PropTypes } from 'prop-types'
import { primaryColor, secondaryColor } from 'constants/colors'

export const ICON_SIZE = {
    LARGE: 24,
    SMALL: 18,
}

const getViewBoxSize = (size) => {
    switch (size) {
        case ICON_SIZE.LARGE:
        case ICON_SIZE.SMALL:
        default:
            return 24
    }
}

const renderIcon = (name, color, size, fill, title) => {
    const boxSize = getViewBoxSize(size)
    switch (name) {
        case 'home':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-gauge"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="9" />
                    <circle cx="12" cy="12" r="1" />
                    <line x1="13.41" y1="10.59" x2="16" y2="8" />
                    <path d="M7 12a5 5 0 0 1 5 -5" />
                </svg>
            )
        case 'chevron-left':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-left"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M15 6L9 12L15 18" stroke="currentColor" />
                </svg>
            )
        case 'chevron-right':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-right"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M9 6L15 12L9 18" stroke="currentColor" />
                </svg>
            )
        case 'sort':
            return (
                <svg
                    width={size}
                    height={size}
                    stroke={color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-130-99h1440v700H-130z" />
                        <g strokeLinecap="round" strokeLinejoin="round">
                            <path d="M1 .857h6M1 6h6M1 11.143h7.714M10.429 3.429L13" />
                        </g>
                    </g>
                </svg>
            )
        case 'sort-asc':
            return (
                <svg
                    width={size}
                    height={size}
                    stroke={color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-130-99h1440v700H-130z" />
                        <g strokeLinecap="round" strokeLinejoin="round">
                            <path d="M1 .857h6M1 6h6M1 11.143h7.714M10.429 3.429L13 .857l2.571 2.572M13 .857v10.286" />
                        </g>
                    </g>
                </svg>
            )
        case 'sort-desc':
            return (
                <svg
                    width={size}
                    height={size}
                    stroke={color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-214-99h1440v700H-214z" />
                        <g strokeLinecap="round" strokeLinejoin="round">
                            <path d="M1 .857h7.714M1 6h6M1 11.143h6M10.429 8.571L13 11.143l2.571-2.572M13 .857v10.286" />
                            <g>
                                <path d="M1 .857h7.714M1 6h6M1 11.143h6M10.429 8.571L13 11.143l2.571-2.572M13 .857v10.286" />
                            </g>
                        </g>
                    </g>
                </svg>
            )
        case 'pencil':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-pencil"
                    width={size}
                    height={size}
                    viewBox={`0 -2 19 19`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path
                        d="M10.241 1.991 12 3.75m-1 4.583V11.5A1.5 1.5 0 0 1 9.5 13h-7A1.5 1.5 0 0 1 1 11.5v-7A1.5 1.5 0 0 1 2.5 3h3.167m4.574-1.009L11.366.866a1.25 1.25 0 0 1 1.768 1.768l-7.08 7.08a3 3 0 0 1-1.264.753L3 11l.533-1.79a3 3 0 0 1 .754-1.265l5.954-5.954v0Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'trash':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-trash"
                    width={size}
                    height={size}
                    viewBox={`0 -2 19 19`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path
                        d="m7.827 5-.231 6m-3.192 0-.23-6m6.645-2.14c.228.035.454.071.681.11m-.681-.11-.712 9.255A1.5 1.5 0 0 1 8.61 13.5H3.389a1.5 1.5 0 0 1-1.496-1.385L1.181 2.86m9.638 0A32.077 32.077 0 0 0 8.5 2.595m-8 .375c.227-.04.453-.076.681-.11m0 0c.77-.116 1.543-.204 2.319-.265m5 0v-.61c0-.787-.607-1.443-1.393-1.468a34.642 34.642 0 0 0-2.214 0C4.107.542 3.5 1.2 3.5 1.985v.61m5 0a32.443 32.443 0 0 0-5 0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'user':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-user"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
            )
        case 'lock':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="5" y="11" width="14" height="10" rx="2" />
                    <circle cx="12" cy="16" r="1" />
                    <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                </svg>
            )
        case 'email':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-at"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="4" />
                    <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
                </svg>
            )
        case 'checkRound':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M17 8.269v.736a8 8 0 1 1-4.744-7.312M17 2.6l-8 8.008-2.4-2.4" />
                </svg>
            )
        case 'checkShield':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="m5.375 9.577 1.75 1.82 3.938-4.093M15 10.032c0 4.464-4.685 7.71-6.39 8.744-.193.117-.29.176-.427.206a.96.96 0 0 1-.366 0c-.137-.03-.234-.089-.428-.206C5.685 17.742 1 14.496 1 10.032V5.683c0-.727 0-1.09.114-1.403.101-.276.266-.523.479-.718.241-.22.569-.348 1.224-.604L7.508 1.13c.182-.071.273-.107.367-.12a.842.842 0 0 1 .25 0c.094.013.185.049.367.12l4.691 1.828c.655.256.983.383 1.224.604.213.196.377.442.479.718.114.312.114.676.114 1.403v4.349Z" />
                </svg>
            )
        case 'upload':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path
                        opacity=".5"
                        stroke="#192132"
                        d="M40.413 29v22M51 39.654H29"
                    />
                </svg>
            )
        case 'uploadimage':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path
                        opacity=".5"
                        d="m20 48.063 10.847-10.577a4.74 4.74 0 0 1 1.535-1 4.836 4.836 0 0 1 3.62 0 4.74 4.74 0 0 1 1.535 1l10.848 10.577m-3.154-3.076 2.962-2.888a4.74 4.74 0 0 1 1.535-1 4.836 4.836 0 0 1 3.62 0 4.741 4.741 0 0 1 1.536 1L61 48.063M23.154 55.75h34.692c.837 0 1.639-.324 2.23-.9A3.037 3.037 0 0 0 61 52.674v-24.6c0-.816-.332-1.598-.924-2.174a3.196 3.196 0 0 0-2.23-.901H23.154c-.837 0-1.639.324-2.23.9A3.037 3.037 0 0 0 20 28.076v24.6c0 .816.332 1.598.924 2.174.591.577 1.393.901 2.23.901ZM45.23 32.687h.017v.017h-.017v-.017Zm.788 0c0 .204-.083.4-.23.544a.799.799 0 0 1-1.116 0 .76.76 0 0 1 0-1.087.799.799 0 0 1 1.115 0c.148.144.231.34.231.544v0Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )

        case 'uploadpdf':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path opacity=".5" d="M0 0h80v80H0z" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.3193 15A5.319 5.319 0 0 0 20 20.3193v38.5541a5.3191 5.3191 0 0 0 5.3193 5.3193h28.9156a5.3193 5.3193 0 0 0 5.3192-5.3193V29.9578a.4964.4964 0 0 0-.1464-.3535L44.9499 15.1464A.4981.4981 0 0 0 44.601 15H25.3193Zm18.777 1h-18.777A4.3193 4.3193 0 0 0 21 20.3193v38.5541a4.3193 4.3193 0 0 0 4.3193 4.3193h28.9156a4.3192 4.3192 0 0 0 4.3192-4.3193V30.4578H44.5963a.5.5 0 0 1-.5-.5V16ZM57.847 29.4578 45.0963 16.707v12.7508H57.847Z"
                        fill="#192132"
                        fillOpacity=".5"
                    />
                    <path
                        d="M28.9588 52v-8.7273h3.1108c.679 0 1.2415.1236 1.6875.3708.446.2471.7798.5852 1.0014 1.0142.2216.4261.3324.9062.3324 1.4403 0 .5369-.1122 1.0199-.3366 1.4489-.2216.4261-.5569.7642-1.0057 1.0142-.446.2471-1.0071.3707-1.6833.3707h-2.1392v-1.1165h2.0199c.429 0 .777-.0738 1.0441-.2215.267-.1506.463-.3552.588-.6137.125-.2585.1875-.5525.1875-.8821 0-.3295-.0625-.6221-.1875-.8778-.125-.2557-.3224-.456-.5923-.6009-.267-.1449-.6193-.2173-1.0568-.2173h-1.6534V52h-1.3168Zm10.5011 0h-2.8253v-8.7273h2.9148c.8551 0 1.5894.1747 2.2031.5242.6136.3466 1.0838.8451 1.4105 1.4957.3295.6477.4943 1.4247.4943 2.331 0 .9091-.1662 1.6903-.4986 2.3437-.3295.6534-.8068 1.1563-1.4318 1.5086-.625.3494-1.3807.5241-2.267.5241Zm-1.5086-1.1506h1.4361c.6648 0 1.2174-.125 1.6577-.375.4403-.2528.7699-.6179.9886-1.0951.2188-.4802.3282-1.0654.3282-1.7557 0-.6847-.1094-1.2656-.3282-1.7429-.2159-.4773-.5383-.8395-.9673-1.0867-.429-.2471-.9616-.3707-1.598-.3707h-1.5171v6.4261ZM45.2947 52v-8.7273h5.412v1.1335h-4.0952v2.6591h3.7074v1.1293h-3.7074V52h-1.3168Z"
                        fill="#888C96"
                    />
                </svg>
            )

        case 'uploadvideo':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path
                        opacity=".5"
                        d="m49.333 37 11.13-5.69a2.399 2.399 0 0 1 2.377.11c.355.223.647.536.85.908.203.372.31.791.31 1.217v16.91c0 .426-.107.845-.31 1.217a2.477 2.477 0 0 1-.85.909 2.406 2.406 0 0 1-2.377.109L49.333 47V37ZM24.89 57h19.555c1.297 0 2.54-.527 3.457-1.465A5.058 5.058 0 0 0 49.333 52V32a5.058 5.058 0 0 0-1.432-3.535A4.834 4.834 0 0 0 44.444 27H24.89c-1.297 0-2.54.527-3.457 1.465A5.058 5.058 0 0 0 20 32v20c0 1.326.515 2.598 1.432 3.535A4.835 4.835 0 0 0 24.889 57Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        opacity=".5"
                        stroke="#192132"
                        d="M34.5 35v13M41 41.5H28"
                    />
                </svg>
            )

        case 'filter':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    fill="none"
                >
                    <path
                        d="M3 1a1 1 0 1 0-2 0v7.268a2 2 0 0 0 0 3.464V13a1 1 0 1 0 2 0v-1.268a2 2 0 0 0 0-3.464V1Zm6 0a1 1 0 0 0-2 0v1.268a2 2 0 0 0 0 3.464V13a1 1 0 1 0 2 0V5.732a2 2 0 0 0 0-3.464V1Zm5-1a1 1 0 0 1 1 1v7.268a2 2 0 0 1 0 3.464V13a1 1 0 0 1-2 0v-1.268a1.999 1.999 0 0 1 0-3.464V1a1 1 0 0 1 1-1Z"
                        fill="#E5361C"
                    />
                </svg>
            )
        case 'search':
            return (
                <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        opacity=".5"
                        d="m15 15-4.667-4.667m1.556-3.889a5.445 5.445 0 1 1-10.89 0 5.445 5.445 0 0 1 10.89 0Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'plus':
            return (
                <svg
                    width="14"
                    height="14"
                    stroke={color}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7 1v6m0 0v6m0-6h6M7 7H1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'send':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-send"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M10 14 21 3M21 3l-6.5 18a.55.55 0 0 1-1 0L10 14l-7-3.5a.55.55 0 0 1 0-1L21 3" />
                </svg>
            )
        case 'import':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                >
                    <path
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M1 10v1.5A1.5 1.5 0 0 0 2.5 13h9a1.5 1.5 0 0 0 1.5-1.5V10M4 4l3-3m0 0 3 3M7 1v9"
                    />
                </svg>
            )
        case 'rounded-plus':
            return (
                <svg
                    width={size}
                    height={size}
                    stroke={color}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9 6.333v5.334M11.667 9H6.333M17 9A7.999 7.999 0 1 1 1.002 9 7.999 7.999 0 0 1 17 9Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'inactive':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-power"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke={secondaryColor}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 6a7.75 7.75 0 1 0 10 0" />
                    <line x1="12" y1="4" x2="12" y2="12" />
                </svg>
            )
        case 'active':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-power"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 6a7.75 7.75 0 1 0 10 0" />
                    <line x1="12" y1="4" x2="12" y2="12" />
                </svg>
            )
        case 'key':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 -2 19 19`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path
                        d="M9.30769 2.84346C9.79732 2.84346 10.2669 3.03802 10.6131 3.38433C10.9593 3.73064 11.1538 4.20034 11.1538 4.69011M13 4.69011C13.0001 5.22878 12.8824 5.76095 12.6551 6.24931C12.4278 6.73766 12.0965 7.17038 11.6844 7.51711C11.2722 7.86384 10.7892 8.1162 10.2693 8.25649C9.74935 8.39678 9.20501 8.42161 8.67446 8.32922C8.328 8.26952 7.96123 8.34523 7.71262 8.59391L6.07692 10.23H4.69231V11.615H3.30769V13H1V11.2654C1 10.8979 1.14585 10.5452 1.40554 10.286L5.40492 6.2856C5.65354 6.03692 5.72923 5.67006 5.66954 5.3235C5.58227 4.81947 5.60064 4.3028 5.72346 3.80624C5.84629 3.30968 6.0709 2.84406 6.38306 2.43888C6.69522 2.0337 7.08813 1.6978 7.53687 1.45247C7.98562 1.20714 8.48042 1.05773 8.98992 1.0137C9.49942 0.969679 10.0125 1.032 10.4967 1.19672C10.9808 1.36145 11.4255 1.62497 11.8025 1.9706C12.1795 2.31624 12.4806 2.73643 12.6868 3.20457C12.8929 3.67271 12.9996 4.17857 13 4.69011V4.69011Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'checkRounded':
            return (
                <svg
                    width="20"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="10" cy="10" r="10" fill="#E6FDEA" />
                    <path
                        d="M7 10.4 8.714 12 13 8"
                        stroke="#70D486"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'export':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="icon icon-tabler icon-tabler-download"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5 5-5M12 4v12" />
                </svg>
            )

        case 'cancel':
            return (
                <svg
                    width="20"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="10" cy="10" r="10" fill="#FDE6E6" />
                    <path
                        d="m8 12 4-4M8 8l4 4"
                        stroke="#E46060"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'waiting':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 100 100"
                >
                    <path
                        fill="#f4d42a"
                        fillOpacity=".2"
                        vectorEffect="non-scaling-stroke"
                        transform="translate(.89 1)"
                        d="M49 0c27.048 0 49 21.952 49 49S76.048 98 49 98 0 76.048 0 49 21.952 0 49 0z"
                    />
                    <path
                        fill="#f4d42a"
                        vectorEffect="non-scaling-stroke"
                        transform="translate(24.89 16.68)"
                        d="M0 4.165A4.161 4.161 0 0 1 4.165 0h41.657a4.161 4.161 0 0 1 4.165 4.165 4.161 4.161 0 0 1-4.166 4.166v1.432c0 5.52-2.2 10.817-6.105 14.723l-8.826 8.839 8.84 8.839a20.832 20.832 0 0 1 6.104 14.722v1.432A4.161 4.161 0 0 1 50 62.484a4.161 4.161 0 0 1-4.166 4.165H4.165A4.161 4.161 0 0 1 0 62.484a4.161 4.161 0 0 1 4.165-4.166v-1.432c0-5.52 2.2-10.817 6.105-14.722l8.826-8.84-8.826-8.838A20.832 20.832 0 0 1 4.165 9.763V8.331A4.161 4.161 0 0 1 0 4.165zm12.496 4.166v1.432c0 3.32 1.315 6.495 3.658 8.839l8.84 8.826 8.838-8.84A12.493 12.493 0 0 0 37.49 9.75V8.33zm0 49.987H37.49v-1.432c0-3.32-1.315-6.495-3.658-8.839l-8.839-8.826-8.839 8.839a12.493 12.493 0 0 0-3.658 8.839v1.432z"
                    />
                </svg>
            )

        case 'draft':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 100 100"
                >
                    <path
                        fill="#688BB6"
                        fillOpacity=".2"
                        vectorEffect="non-scaling-stroke"
                        transform="translate(1.5 1)"
                        d="M49 0c27.048 0 49 21.952 49 49S76.048 98 49 98 0 76.048 0 49 21.952 0 49 0z"
                    />
                    <path
                        fill="#688BB6"
                        vectorEffect="non-scaling-stroke"
                        transform="translate(25.5 25)"
                        d="m40.12 22.586 1.104-1.104-3.314-3.314-6.073-6.07-3.314-3.315-1.105 1.105-2.21 2.21L5.73 31.57a8.666 8.666 0 0 0-2.171 3.656L.097 46.996c-.245.82-.02 1.71.596 2.317.616.606 1.497.83 2.317.596l11.764-3.46a8.67 8.67 0 0 0 3.657-2.17L37.91 24.804zM15.642 39.048l-.89 2.22c-.39.302-.83.527-1.3.674L5.806 44.19l2.25-7.635c.137-.479.371-.919.675-1.3l2.22-.89v3.129c0 .86.704 1.564 1.564 1.564h3.13zM35.465 1.833 34.057 3.25l-2.21 2.21-1.114 1.104 3.314 3.314 6.073 6.07 3.314 3.315 1.105-1.105 2.21-2.21 1.417-1.417a6.252 6.252 0 0 0 0-8.847l-3.843-3.851a6.258 6.258 0 0 0-8.85 0zM30.83 18.256 16.748 32.332a1.57 1.57 0 0 1-2.21 0 1.569 1.569 0 0 1 0-2.209L28.62 16.046a1.57 1.57 0 0 1 2.21 0 1.569 1.569 0 0 1 0 2.21z"
                    />
                </svg>
            )
        case 'back':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-arrow-narrow-left"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="5" y1="12" x2="9" y2="16" />
                    <line x1="5" y1="12" x2="9" y2="8" />
                </svg>
            )
        case 'link':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-link"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                </svg>
            )
        case 'unlink':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-unlink"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4-4a3.5 3.5 0 0 0-5-5l-.5.5" />
                    <path d="M14 10a3.5 3.5 0 0 0-5 0l-4 4a3.5 3.5 0 0 0 5 5l.5-.5M16 21v-2M19 16h2M3 8h2M8 3v2" />
                </svg>
            )
        case 'copy':
            return (
                <svg
                    width="24"
                    height="24"
                    fill="none"
                    stroke={color}
                    xmlns="http://www.w3.org/2000/svg"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                >
                    <path d="M17.4373 8.5C18.3715 8.8814 19 9.5944 19 10.411v5.8829c0 1.2184-1.3993 2.2061-3.1254 2.2061h-4.1673c-1.1568 0-2.1669-.4436-2.7073-1.1031M8 15h4c1.6569 0 3-1.3431 3-3V8c0-1.6569-1.3431-3-3-3H8C6.3431 5 5 6.3431 5 8v4c0 1.6569 1.3431 3 3 3Z" />
                </svg>
            )
        default:
            return null
    }
}

const Icon = ({ name, color, size, fill, title }) => {
    return renderIcon(name, color, size, fill, title)
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number,
    title: PropTypes.string,
}

Icon.defaultProps = {
    color: primaryColor,
    size: ICON_SIZE.LARGE,
    fill: 'none',
    title: '',
}

export default Icon
