import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITY from 'constants/entities'
import { LARGE_SCREEN_WIDTH, LAYOUTS } from 'constants/enums'

import Footer from './Footer'
import Header from './Header'
import Sidebar from 'components/Sidebar'

const AuthLayout = ({ children }) => {
    const { projectId, securityCode } = useParams()

    const { data } = useFetchDataById(
        ENTITY.APPLICANT_PROJECT,
        projectId,
        {
            securityCode,
            include:
                'applicationImage,coverImage,projectImage,votingImage,company',
        }
    )

    const hocRef = useRef(null)
    const hamBtnRef = useRef(null)

    const [openSidebar, setOpenSidebar] = useState(false)

    const handleClickOutside = (event) => {
        if (
            document.body.clientWidth <= LARGE_SCREEN_WIDTH &&
            hocRef &&
            hocRef.current &&
            !hocRef.current.contains(event.target) &&
            !hamBtnRef.current.contains(event.target)
        ) {
            setOpenSidebar(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    if (!data) {
        return null
    }

    return (
        <div className="auth__wrapper">
            <Sidebar
                open={openSidebar}
                setOpen={setOpenSidebar}
                hocRef={hocRef}
                layout={LAYOUTS.AUTH}
            />
            <div
                className={`m-main -auth ${
                    openSidebar ? '-sidebarIsOpen' : ''
                }`}
            >
                <Header
                    setOpenSidebar={setOpenSidebar}
                    hamBtnRef={hamBtnRef}
                    openSidebar={openSidebar}
                    company={data}
                />
                <main>
                    {children ? children : <Outlet context={data} />}
                </main>
                <Footer company={data.company} />
            </div>
        </div>
    )
}

export default AuthLayout
