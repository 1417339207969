import { ALL_ROLES, LAYOUTS, ROLES } from 'constants/enums'
import ROUTES from 'constants/routes'

const { ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN } = ROLES

export const getMenuItems = (
    layout,
    projectId,
    encodedProjectName,
    securityCodeParam
) => {
    if (layout === LAYOUTS.ADMIN) {
        return [
            {
                key: 'campaign-management',
                label: 'menuItems.campaignManagement',
                to: ROUTES.CAMPAIGN_MANAGEMENT.path,
                roles: ALL_ROLES,
            },
            {
                key: 'user-management',
                label: 'menuItems.userManagement',
                to: ROUTES.USER_MANAGEMENT.path,
                roles: [ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN],
            },
            {
                key: 'company-management',
                label: 'menuItems.companyManagement',
                to: ROUTES.COMPANY_MANAGEMENT.path,
                roles: [ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN],
            },
        ]
    }

    return [
        {
            key: 'about-campaign',
            label: 'menuItems.aboutCampaign',
            to: `/${ROUTES.ABOUT_CAMPAIGN.path}/${projectId}/${encodedProjectName}${securityCodeParam}`,
            roles: ALL_ROLES,
        },
        {
            key: 'approved-applications',
            label: 'menuItems.approvedApplications',
            to: `/${ROUTES.APPROVED_APPLICATIONS.path}/${projectId}/${encodedProjectName}${securityCodeParam}`,
            roles: ALL_ROLES,
        },
    ]
}
