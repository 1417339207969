import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import ROUTES from 'constants/routes'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const SidebarItem = ({ label, to, icon, subitems, active, setActive, setOpen }) => {
    const t = useTranslate()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const handleClick = (e, to) => {
        e.stopPropagation()
        e.preventDefault()
        navigate(to)
    }

    const handleSetActive = () => {
        if (subitems) {
            setActive(active === to ? null : to)
        } else {
            setActive(to)
        }
        setOpen(false)
    }

    const getIsActive = (path) => {
        if (path !== ROUTES.HOME.path) return pathname.includes(path)
        return pathname === ROUTES.HOME.path
    }

    return (
        <Link
            className={`m-sidebar__itemContainer ${
                active === to ? ' -active' : ''
            }`}
            onClick={handleSetActive}
            to={subitems ? pathname : to}
        >
            <div
                className={`m-sidebar__item ${
                    getIsActive(to) ? ' -active' : ''
                }`}
                title={t(label)}
            >
                {icon && (
                    <Icon name={icon} color="#929BB3" size={ICON_SIZE.SMALL} />
                )}
                <span className="m-sidebar__itemLabel">{t(label)}</span>
                {subitems && <span className="m-sidebar__subItemsIcon"></span>}
            </div>
            {subitems && (
                <div className="m-sidebar__subitems">
                    {subitems.map((item) => (
                        <div
                            className={`m-sidebar__subitem ${
                                getIsActive(item.to) ? ' -active' : ''
                            }`}
                            key={item.key}
                            onClick={(e) => handleClick(e, item.to)}
                            title={t(item.label)}
                        >
                            {item.icon && (
                                <Icon
                                    name={item.icon}
                                    color="#B4B9C4"
                                    size={ICON_SIZE.SMALL}
                                />
                            )}
                            <span className="m-sidebar__subitemLabel">
                                {t(item.label)}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </Link>
    )
}

SidebarItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    icon: PropTypes.string,
    subitems: PropTypes.array,
    active: PropTypes.string,
    setActive: PropTypes.func,
    setOpen: PropTypes.func
}

export default SidebarItem
