import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import { useParams } from 'react-router-dom'

import useFetchDataById from 'hooks/useFetchDataById'

import { BASE_FILE_URL } from 'utils/axiosClient'

import ENTITY from 'constants/entities'

import footerLogo from 'assets/images/raiffeisen_logo_w.png'

const Footer = ({ company }) => {
    const t = useTranslate()

    const { projectId, securityCode } = useParams()

    const { data } = useFetchDataById(ENTITY.APPLICANT_PROJECT, projectId, {
        securityCode,
    })
    const { _links } = data ?? []

    const {
        name,
        footerImagePath,
        imagePath,
        copyrightText,
        termsAndConditionsPath,
        privacyPolicyPath,
        street,
        secondStreet,
        zip,
        city,
        phone,
        email,
    } = company || {}

    const copyrightSign = String.fromCodePoint(0x00a9)
    const copyright = `${copyrightSign} ${new Date().getFullYear()} ${name || ''}`

    const showCompanyInfo = !!(termsAndConditionsPath || privacyPolicyPath)

    const getLink = (item) => {
        if (item?.linkName && item?.linkURL) {
            return (
                <a
                    href={
                        item.linkURL.startsWith('http')
                            ? item.linkURL
                            : '//' + item.linkURL
                    }
                    target="_blank"
                >
                    {item.linkName}
                </a>
            )
        } else {
            if (item?.linkName) {
                return <p>{item.linkName}</p>
            }
            return (
                <a
                    href={
                        item.linkURL.startsWith('http')
                            ? item.linkURL
                            : '//' + item.linkURL
                    }
                    target="_blank"
                >
                    {item.linkURL}
                </a>
            )
        }
    }

    return (
        <footer
            id="footer-main"
            className="m-footer -sixteen"
            style={
                footerImagePath && {
                    backgroundImage: `url("${BASE_FILE_URL}/${encodeURI(
                        footerImagePath
                    )}")`,
                }
            }
        >
            <div className="_wr">
                <div className="_w">
                    <div className=" ofs_l1 _s4 _l4 m-footer__column -main">
                        <div className="m-footer__column--logo">
                            <img
                                src={
                                    imagePath
                                        ? `${BASE_FILE_URL}/${imagePath}`
                                        : footerLogo
                                }
                            />
                        </div>
                        {copyrightText && (
                            <span className="-copy">{copyrightText}</span>
                        )}
                        <div className="m-footer__column--links">
                            {street && <span>{street}</span>}
                            {secondStreet && <span>{secondStreet}</span>}
                            {zip && city && (
                                <span>
                                    {zip} {city}
                                </span>
                            )}
                            {phone && <a href={`tel:${phone}`}>{phone}</a>}
                            {email && <a href={`mailto:${email}`}>{email}</a>}
                        </div>
                    </div>

                    {_links?.length > 0 && (
                        <div className="_s4 _l4 m-footer__column">
                            <span className="-mainLink">
                                {t('general.linkMain')}
                            </span>
                            <div className="m-footer__column--links">
                                {_links.map((item, index) => (
                                    <Fragment key={index}>
                                        {getLink(item)}
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    )}

                    {showCompanyInfo && (
                        <div className="_s4 _l3 m-footer__column">
                            <span className="-mainLink">
                                {t('footer.company')}
                            </span>
                            <div className="m-footer__column--links">
                                {termsAndConditionsPath && (
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={`${BASE_FILE_URL}/${termsAndConditionsPath}`}
                                    >
                                        {t('footer.terms')}
                                    </a>
                                )}
                                {privacyPolicyPath && (
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={`${BASE_FILE_URL}/${privacyPolicyPath}`}
                                    >
                                        {t('footer.privacy')}
                                    </a>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="m-footer__row ofs_l1 -padtop40">
                    <p>{copyright}</p>
                    <a
                        href="https://www.raiffeisen.ch/rigi/de/ueber-uns/raiffeisen-gruppe/disclaimer-website.html"
                        target="_blank"
                    >
                        {t('general.disclaimer')}
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
