import React, { useEffect, useState } from 'react'

import {
    getUserId,
    getCompanyId,
    setCompanyId,
} from 'services/localStorage.service'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITY from 'constants/entities'
import { ROLES } from 'constants/enums'

const { ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN } = ROLES

export const CurrentUserContext = React.createContext()

const CurrentUserContextProvider = (props) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [isCompanyAdmin, setIsCompanyAdmin] = useState(false)
    const [reload, setReload] = useState(false)

    const userId = getUserId()
    const companyId = getCompanyId()

    const { data, isLoading: isLoadingCurrentUser } = useFetchDataById(
        ENTITY.USER,
        userId,
        { include: 'role,company' },
        reload
        // NOTE leave currentUser until edge cases are covered
        // currentUser === null
    )

    useEffect(() => {
        setCurrentUser(data)

        if (data) {
            setCompanyId(data?.company?.id)
        }
    }, [data])

    useEffect(() => {
        switch (currentUser?.role?.name) {
            case ROLE_SUPER_ADMIN.name:
                setIsSuperAdmin(true)
                setIsCompanyAdmin(false)
                break
            case ROLE_COMPANY_ADMIN.name:
                setIsCompanyAdmin(true)
                setIsSuperAdmin(false)
                break
            default:
                setIsSuperAdmin(false)
                setIsCompanyAdmin(false)
                break
        }
    }, [currentUser])

    const reloadCurrentUser = () => {
        setReload(!reload)
    }

    return (
        <CurrentUserContext.Provider
            value={{
                currentUser,
                setCurrentUser,
                isLoadingCurrentUser,
                userId,
                companyId,
                isSuperAdmin,
                isCompanyAdmin,
                reloadCurrentUser,
            }}
        >
            {props.children}
        </CurrentUserContext.Provider>
    )
}

export default CurrentUserContextProvider
