import React, { Fragment } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import useFetchDataById from 'hooks/useFetchDataById'

import ROUTE from 'constants/routes'
import ENTITY from 'constants/entities'

import AuthLayout from '../AuthLayout'
import Header from './Header'

const NoAuthLayout = ({ children }) => {
    const { pathname } = useLocation()

    const isRegisterForm = pathname === `/${ROUTE.REGISTER?.path}`
    const isVoterRegisterForm = pathname === `/${ROUTE.VOTER_REGISTER?.path}`
    const isRegisterApplicationForm = pathname.includes(
        ROUTE.REGISTER_APPLICATION?.path
    )

    const { campaignId, campaignSecurityCode } = useParams()

    const { data } = useFetchDataById(
        ENTITY.APPLICANT_PROJECT,
        campaignId,
        {
            securityCode: campaignSecurityCode,
            include: 'company',
        },
        true,
        isRegisterApplicationForm
    )

    const { company } = data || {}

    if (!data && isRegisterApplicationForm) {
        return null
    }

    if (pathname === ROUTE.HOME?.path)
        return <AuthLayout>{children}</AuthLayout>

    return (
        <Fragment>
            <div className="_wr-f">
                <div className="_w">
                    <div
                        className={` m-card -form -bg -p _12 ${
                            isRegisterForm ? '_l7' : '_l5'
                        }`}
                    >
                        <Header company={company} />
                        {children ? children : <Outlet />}
                    </div>
                    <div
                        className={`m-card -noAuth -dark -bg hidden _12 ${
                            isRegisterForm ? '_l5' : '_l7'
                        }`}
                    >
                        <div
                            className={
                                isVoterRegisterForm
                                    ? 'm-card__img -voter-register'
                                    : 'm-card__img'
                            }
                        ></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NoAuthLayout
