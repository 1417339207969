import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import AvatarDropdown from './AvatarDropdown'

import { BASE_FILE_URL } from 'utils/axiosClient'

import headerLogo from 'assets/images/raiffeisen_logo.png'

const Header = ({ openSidebar, setOpenSidebar, hamBtnRef }) => {
    const { currentUser } = useContext(CurrentUserContext)

    const companyImage = currentUser?.company?.imagePath

    return (
        <header className="m-adminHeader -topLevel">
            <div className="m-adminHeader__logo">
                <img
                    className={`${companyImage ? '-companyLogo' : ''}`}
                    src={
                        companyImage
                            ? `${BASE_FILE_URL}/${companyImage}`
                            : headerLogo
                    }
                ></img>
            </div>

            <div
                className="m-toggler"
                ref={hamBtnRef}
                onClick={() => setOpenSidebar(!openSidebar)}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className="m-adminHeader__main">
                <AvatarDropdown />
            </div>
        </header>
    )
}

Header.propTypes = {
    openSidebar: PropTypes.bool.isRequired,
    setOpenSidebar: PropTypes.func.isRequired,
}

export default Header
