const ENTITY = {
    USER: 'users',
    LANGUAGE: 'languages',
    PROJECT: 'projects',
    ROLE: 'roles',
    COUNTRY: 'countries',
    COMPANY: 'companies',
    APPLICATION: 'applications',
    VOTERS: 'voters',
    APPLICANT_PROJECT: 'applicant_projects',
    APPLICATION_STATUS: 'application_statuses',
    PROJECT_APPLICATION: 'project_applications',
    PROJECT_STATISTIC: 'project_statistics',
    PROJECT_LISTS: 'project_lists',
    VIDEO: 'videos/delete',
}

export default ENTITY
