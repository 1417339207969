import React from 'react'

import { BASE_FILE_URL } from 'utils/axiosClient'

import LanguagePicker from 'components/LanguagePicker'

const Header = ({ company }) => {
    return (
        <header className="m-nav -noAuth">
            <div
                className="m-nav__logo"
                style={
                    company?.imagePath && {
                        backgroundImage: `url("${BASE_FILE_URL}/${encodeURI(
                            company?.imagePath
                        )}")`,
                    }
                }
            ></div>
            {/* <LanguagePicker /> */}
        </header>
    )
}

export default Header
