import React, { Fragment, useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import loadable from 'utils/loadable'

import { getAnonymousRoutes } from './AnonymousRoutes'

import ROUTES from 'constants/routes'
import { ROLES } from 'constants/enums'

import AdminLayout from 'components/layouts/AdminLayout'

const CampaignManagement = loadable(() =>
    import('screens/admin/CampaignManagement')
)
const SingleCampaign = loadable(() =>
    import('screens/admin/CampaignManagement/SingleCampaign')
)
const GeneralSettings = loadable(() =>
    import('screens/admin/CampaignManagement/SingleCampaign/GeneralSettings')
)
const VotersList = loadable(() =>
    import('screens/admin/CampaignManagement/SingleCampaign/VotersList')
)
const SubmittedApplications = loadable(() =>
    import(
        'screens/admin/CampaignManagement/SingleCampaign/SubmittedApplications'
    )
)
const SingleApplication = loadable(() =>
    import(
        'screens/admin/CampaignManagement/SingleCampaign/SubmittedApplications/SingleApplication'
    )
)
const CompanyManagement = loadable(() =>
    import('screens/admin/CompanyManagement')
)
const CompanyAdminManagement = loadable(() =>
    import('screens/admin/CompanyAdminManagement')
)
const UserManagement = loadable(() => import('screens/admin/UserManagement'))
const UserProfile = loadable(() => import('screens/admin/UserProfile'))

const { ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN } = ROLES

const AuthRoutes = () => {
    const { currentUser } = useContext(CurrentUserContext)

    if (!currentUser) return null

    const renderAllowedRoutes = () => {
        switch (currentUser.role.name) {
            case ROLE_SUPER_ADMIN.name:
                return (
                    <Fragment>
                        <Route element={<AdminLayout />}>
                            <Route
                                path={ROUTES.USER_MANAGEMENT.path}
                                element={
                                    <UserManagement
                                        title={ROUTES.USER_MANAGEMENT.title}
                                    />
                                }
                            />
                            <Route
                                path={ROUTES.COMPANY_MANAGEMENT.path}
                                element={
                                    <CompanyManagement
                                        title={ROUTES.COMPANY_MANAGEMENT.title}
                                    />
                                }
                            />
                            {renderCommonRoutes()}
                        </Route>
                        {getAnonymousRoutes()}
                    </Fragment>
                )

            case ROLE_COMPANY_ADMIN.name:
                return (
                    <Fragment>
                        <Route element={<AdminLayout />}>
                            <Route
                                path={ROUTES.USER_MANAGEMENT.path}
                                element={
                                    <UserManagement
                                        title={ROUTES.USER_MANAGEMENT.title}
                                    />
                                }
                            />
                            <Route
                                path={ROUTES.COMPANY_MANAGEMENT.path}
                                element={
                                    <CompanyAdminManagement
                                        title={
                                            ROUTES.COMPANY_ADMIN_MANAGEMENT
                                                .title
                                        }
                                    />
                                }
                            />
                            {renderCommonRoutes()}
                        </Route>
                        {getAnonymousRoutes()}
                    </Fragment>
                )

            default:
                return <Fragment>{getAnonymousRoutes()}</Fragment>
        }
    }

    const renderCommonRoutes = () => {
        return (
            <Fragment>
                <Route
                    path={ROUTES.CAMPAIGN_MANAGEMENT.path}
                    element={
                        <CampaignManagement
                            title={ROUTES.CAMPAIGN_MANAGEMENT.title}
                        />
                    }
                />
                <Route
                    path={`${ROUTES.CAMPAIGN_MANAGEMENT.path}/${ROUTES.SINGLE_CAMPAIGN.path}/:campaignId/:campaignHash`}
                    element={
                        <SingleCampaign title={ROUTES.SINGLE_CAMPAIGN.title} />
                    }
                >
                    <Route
                        index
                        element={
                            <GeneralSettings
                                title={ROUTES.SINGLE_CAMPAIGN.title}
                            />
                        }
                    />
                    <Route
                        path={`${ROUTES.GENERAL_SETTINGS.path}`}
                        element={
                            <GeneralSettings
                                title={ROUTES.SINGLE_CAMPAIGN.title}
                            />
                        }
                    />
                    <Route
                        path={`${ROUTES.VOTERS_LIST.path}`}
                        element={
                            <VotersList title={ROUTES.VOTERS_LIST.title} />
                        }
                    />
                    <Route
                        path={`${ROUTES.SUBMITTED_APPLICATIONS.path}`}
                        element={
                            <SubmittedApplications
                                title={ROUTES.SUBMITTED_APPLICATIONS.title}
                            />
                        }
                    />
                </Route>
                <Route
                    path={`${ROUTES.CAMPAIGN_MANAGEMENT.path}/${ROUTES.SINGLE_CAMPAIGN.path}/:campaignId/:campaignHash/${ROUTES.SUBMITTED_APPLICATIONS.path}/:applicationId`}
                    element={<SingleApplication />}
                />
                <Route
                    path={ROUTES.USER_PROFILE.path}
                    element={<UserProfile title={ROUTES.USER_PROFILE.title} />}
                />
            </Fragment>
        )
    }

    return (
        <Routes>
            {renderAllowedRoutes()}
            <Route
                path="*"
                element={<Navigate to={ROUTES.CAMPAIGN_MANAGEMENT.path} />}
            />
        </Routes>
    )
}

export default AuthRoutes
