/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { clearStorage } from 'services/localStorage.service'

import { ALL_ROLES } from 'constants/enums'
import ROUTE from 'constants/routes'

import Avatar from 'components/Avatar'
import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'

const AvatarDropdown = ({ open }) => {
    const t = useTranslate()

    const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

    const handleLogout = () => {
        setCurrentUser(null)
        clearStorage()
    }

    if (!currentUser) return null

    const { firstName, lastName, role } = currentUser

    const roleName = ALL_ROLES.find((item) => item.name === role.name)?.label

    return (
        <div className="m-avatar">
            <Avatar
                firstName={firstName}
                lastName={lastName}
                role={roleName ? t(roleName) : ''}
                avatar=""
            />
            <i className={`a-chevron ${open ? '-up' : '-down'}`} />
            <div className={`m-avatar__popup ${open ? '-open' : ''}`}>
                <Link
                    className="m-avatar__item -logout"
                    to={ROUTE.USER_PROFILE.path}
                >
                    {t('button.userProfile')}
                </Link>
                <Link
                    className="m-avatar__item -logout"
                    to="#"
                    onClick={handleLogout}
                >
                    {t('button.logout')}
                </Link>
            </div>
        </div>
    )
}

AvatarDropdown.propTypes = {
    open: PropTypes.bool.isRequired,
}

export default clickOutsideHOC(AvatarDropdown)
