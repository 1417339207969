const ROUTE = {
    HOME: {
        path: '/',
        title: 'pageTitle.campaignManagement',
    },
    LOGIN: {
        path: 'login',
        title: 'pageTitle.login',
    },
    REGISTER_APPLICATION: {
        path: 'register-application',
        title: 'pageTitle.registerApplication',
    },
    FORGOT_PASSWORD: {
        path: 'forgot-password',
        title: 'pageTitle.forgotPassword',
    },
    RESET_PASSWORD: {
        path: 'reset-password',
        title: 'pageTitle.resetPassword',
    },
    SET_PASSWORD: {
        path: 'set-password',
        title: 'pageTitle.setPassword',
    },
    CAMPAIGN_MANAGEMENT: {
        path: 'campaign-management',
        title: 'pageTitle.campaignManagement',
    },
    SINGLE_CAMPAIGN: {
        path: 'single-campaign',
        title: 'pageTitle.singleCampaign',
    },
    GENERAL_SETTINGS: {
        path: 'general-settings',
        title: 'pageTitle.generalSettings',
    },
    VOTERS_LIST: { path: 'voters-list', title: 'pageTitle.votersList' },
    SUBMITTED_APPLICATIONS: {
        path: 'submitted-applications',
        title: 'pageTitle.submittedApplications',
    },
    USER_MANAGEMENT: {
        path: 'user-management',
        title: 'pageTitle.userManagement',
    },
    COMPANY_MANAGEMENT: {
        path: 'company-management',
        title: 'pageTitle.companyManagement',
    },
    COMPANY_ADMIN_MANAGEMENT: {
        path: 'company-admin-management',
        title: 'pageTitle.companySettings',
    },
    VOTER_REGISTER: {
        path: 'voter-register',
        title: 'pageTitle.voterRegister',
    },
    USER_PROFILE: { path: 'user-profile', title: 'pageTitle.userProfile' },
    APPROVED_APPLICATIONS: {
        path: 'approved-applications',
        title: 'pageTitle.approvedApplications',
    },
    APPLICATION_PAGE: {
        path: 'application-page',
        title: 'Application page',
    },
    ABOUT_CAMPAIGN: { path: 'campaign', title: 'pageTitle.campaign' },
    PREVIEW: { path: 'preview' },
    ADMIN_PREVIEW: { path: 'admin-preview' },
    NOT_FOUND_PAGE: { path: 'not-found', title: 'pageTitle.notFound' },
}

export default ROUTE
