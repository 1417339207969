import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import useMenuItems from './useMenuItems'

import { LARGE_SCREEN_WIDTH, LAYOUTS } from 'constants/enums'

import SidebarItem from './SidebarItem'

const Sidebar = ({ open, setOpen, hocRef, canToggle, layout }) => {
    const { width } = useWindowDimensions()
    const menuItems = useMenuItems(layout)

    const [active, setActive] = useState()

    const className = useMemo(() => {
        let name = 'm-sidebar '

        if (layout === LAYOUTS.ADMIN) {
            name = name.concat('-lowerLevel')
            if (!open && width < LARGE_SCREEN_WIDTH) {
                name = name.concat(' -closed')
            }
        } else {
            name = name.concat('-lowerLevelAuth')
            if (!open) {
                name = name.concat(' -closed__auth')
            }
        }

        return name
    }, [open, layout])

    return (
        <div>
            <div ref={hocRef} className={className}>
                {canToggle && (
                    <div
                        className="m-sidebar__toggler"
                        onClick={() => setOpen(!open)}
                    />
                )}

                <div className="m-sidebar__scroll">
                    {menuItems.map(({ key, label, to, icon, subitems }) => (
                        <SidebarItem
                            key={key}
                            label={label}
                            to={to}
                            icon={icon}
                            subitems={subitems}
                            active={active}
                            setActive={setActive}
                            setOpen={setOpen}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    canToggle: PropTypes.bool,
}

Sidebar.defaultProps = {
    canToggle: false,
}

export default Sidebar
