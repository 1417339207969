import React, { Fragment } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import loadable from 'utils/loadable'

import ROUTE from 'constants/routes'

import { getAnonymousRoutes } from './AnonymousRoutes'

import NoAuthLayout from 'components/layouts/NoAuthLayout'

const Login = loadable(() => import('screens/noAuth/Login'))
const ForgotPassword = loadable(() => import('screens/noAuth/ForgotPassword'))
const ResetPassword = loadable(() => import('screens/noAuth/ResetPassword'))

const NoAuthRoutes = () => {
    return (
        <Routes>
            <Route element={<NoAuthLayout />}>
                <Route
                    path={ROUTE.LOGIN.path}
                    element={<Login title={ROUTE.LOGIN.title} />}
                />
                <Route
                    path={`${ROUTE.FORGOT_PASSWORD.path}`}
                    element={
                        <ForgotPassword title={ROUTE.FORGOT_PASSWORD.title} />
                    }
                />
                <Route
                    path={`${ROUTE.SET_PASSWORD.path}/:token`}
                    element={
                        <ResetPassword
                            title={ROUTE.SET_PASSWORD.title}
                            buttonTitle="button.addPassword"
                        />
                    }
                />
                <Route
                    path={`${ROUTE.RESET_PASSWORD.path}/:token`}
                    element={
                        <ResetPassword
                            title={ROUTE.RESET_PASSWORD.title}
                            buttonTitle="button.changePassword"
                        />
                    }
                />
            </Route>
            <Route>{getAnonymousRoutes()}</Route>
            <Route path="*" element={<Navigate to={ROUTE.LOGIN.path} />} />
        </Routes>
    )
}

export default NoAuthRoutes
