import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'

import loadable from 'utils/loadable'

import ROUTES from 'constants/routes'

import NoAuthLayout from 'components/layouts/NoAuthLayout'
import AuthLayout from '../components/layouts/AuthLayout'

const AboutCampaign = loadable(() => import('screens/user/AboutCampaign'))
const ApplicationPage = loadable(() => import('screens/user/ApplicationPage'))
const ApprovedApplications = loadable(() =>
    import('screens/user/ApprovedApplications')
)
const VoterRegister = loadable(() => import('screens/noAuth/VoterRegister'))
const ChangeDraft = loadable(() => import('screens/user/ChangeDraft'))
const RegisterApplication = loadable(() =>
    import('screens/user/RegisterApplication')
)
const NotFoundPage = loadable(() => import('screens/common/NotFoundPage'))

export const getAnonymousRoutes = () => {
    return (
        <Fragment>
            <Route element={<NoAuthLayout />}>
                <Route
                    // Change application draft (link from mail) for private campaign (campaignSecurityCode is required)
                    path={`${ROUTES.ABOUT_CAMPAIGN.path}/:campaignId/:campaignName/:campaignSecurityCode/${ROUTES.REGISTER_APPLICATION.path}/:draftToken`}
                    element={<ChangeDraft />}
                />
                <Route
                    // Change application draft (link from mail) for public campaign
                    path={`${ROUTES.ABOUT_CAMPAIGN.path}/:campaignId/:campaignName/${ROUTES.REGISTER_APPLICATION.path}/:draftToken`}
                    element={<ChangeDraft />}
                />
                <Route
                    // Register application for private campaign (campaignSecurityCode is required)
                    path={`${ROUTES.ABOUT_CAMPAIGN.path}/:campaignId/:campaignName/:campaignSecurityCode/${ROUTES.REGISTER_APPLICATION.path}`}
                    element={
                        <RegisterApplication
                            title={ROUTES.REGISTER_APPLICATION.title}
                        />
                    }
                />
                <Route
                    // Register application for public campaign
                    path={`${ROUTES.ABOUT_CAMPAIGN.path}/:campaignId/:campaignName/${ROUTES.REGISTER_APPLICATION.path}`}
                    element={
                        <RegisterApplication
                            title={ROUTES.REGISTER_APPLICATION.title}
                        />
                    }
                />
                <Route
                    path={`${ROUTES.ABOUT_CAMPAIGN.path}/:projectId/:projectName/${ROUTES.VOTER_REGISTER.path}`}
                    element={
                        <VoterRegister title={ROUTES.VOTER_REGISTER.title} />
                    }
                />
            </Route>

            <Route
                // Draft preview (layout is not needed) for private campaign (securityCode is required)
                path={`${ROUTES.APPROVED_APPLICATIONS.path}/:projectId/:projectName/:securityCode/${ROUTES.APPLICATION_PAGE.path}/${ROUTES.PREVIEW.path}`}
                element={<ApplicationPage />}
            />
            <Route
                // Draft preview (layout is not needed) for public campaign
                path={`${ROUTES.APPROVED_APPLICATIONS.path}/:projectId/:projectName/${ROUTES.APPLICATION_PAGE.path}/${ROUTES.PREVIEW.path}`}
                element={<ApplicationPage />}
            />
            <Route
                // Draft preview (layout is not needed) for private campaign (securityCode is required) - admin
                path={`${ROUTES.APPROVED_APPLICATIONS.path}/:projectId/:projectName/:securityCode/${ROUTES.APPLICATION_PAGE.path}/${ROUTES.ADMIN_PREVIEW.path}/:applicationId`}
                element={<ApplicationPage />}
            />
            <Route
                // Draft preview (layout is not needed) for public campaign - admin
                path={`${ROUTES.APPROVED_APPLICATIONS.path}/:projectId/:projectName/${ROUTES.APPLICATION_PAGE.path}/${ROUTES.ADMIN_PREVIEW.path}/:applicationId`}
                element={<ApplicationPage />}
            />

            <Route element={<AuthLayout />}>
                <Route
                    // Public campaign
                    path={`${ROUTES.ABOUT_CAMPAIGN.path}/:projectId/:projectName`}
                    element={<AboutCampaign />}
                />
                <Route
                    // Private campaign (securityCode is required)
                    path={`${ROUTES.ABOUT_CAMPAIGN.path}/:projectId/:projectName/:securityCode`}
                    element={<AboutCampaign />}
                />
                <Route
                    // Approved applications for private campaign (securityCode is required)
                    path={`${ROUTES.APPROVED_APPLICATIONS.path}/:projectId/:projectName/:securityCode`}
                    element={
                        <ApprovedApplications
                            title={ROUTES.APPROVED_APPLICATIONS.title}
                        />
                    }
                />
                <Route
                    // Approved applications for public campaign
                    path={`${ROUTES.APPROVED_APPLICATIONS.path}/:projectId/:projectName`}
                    element={
                        <ApprovedApplications
                            title={ROUTES.APPROVED_APPLICATIONS.title}
                        />
                    }
                />
                <Route
                    // Application page for private campaign (securityCode is required)
                    path={`${ROUTES.APPROVED_APPLICATIONS.path}/:projectId/:projectName/:securityCode/${ROUTES.APPLICATION_PAGE.path}/:applicationId`}
                    element={<ApplicationPage />}
                />
                <Route
                    // Application page for public campaign
                    path={`${ROUTES.APPROVED_APPLICATIONS.path}/:projectId/:projectName/${ROUTES.APPLICATION_PAGE.path}/:applicationId`}
                    element={<ApplicationPage />}
                />
            </Route>

            <Route
                path={ROUTES.NOT_FOUND_PAGE.path}
                element={<NotFoundPage title={ROUTES.NOT_FOUND_PAGE.title} />}
            />
        </Fragment>
    )
}
