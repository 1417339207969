import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import { BASE_FILE_URL } from 'utils/axiosClient'

import { LARGE_SCREEN_WIDTH } from 'constants/enums'
import ROUTE from 'constants/routes'


const Header = ({ company, openSidebar, setOpenSidebar, hamBtnRef }) => {
    const t = useTranslate()
    const { width } = useWindowDimensions()
    const { pathname } = useLocation()
    const { projectId, projectName, securityCode } = useParams()
    const encodedProjectName = encodeURIComponent(projectName)

    const [openNavbar, setOpenNavbar] = useState(false)

    const isActive = (path) => pathname.includes(path)

    const securityCodeParam = securityCode ? `/${securityCode}` : ''

    const isLargeScreen = width > LARGE_SCREEN_WIDTH

    useEffect(() => {
        if(isLargeScreen) setOpenSidebar(false)
    }, [isLargeScreen])
    

    return (
        <header>
            <nav>
                <div className={`${isLargeScreen ? '_wr' : ''}`}>
                    <div className="_w">
                        <div className="_12 m-nav -auth">
                            <div
                                className="m-toggler -auth"
                                ref={hamBtnRef}
                                onClick={() => setOpenSidebar(!openSidebar)}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <a
                                href="/"
                                className="m-nav__logo -auth"
                                style={
                                    company?.imagePath && {
                                        backgroundImage: `url("${BASE_FILE_URL}/${encodeURI(
                                            company?.imagePath
                                        )}")`,
                                    }
                                }
                            ></a>

                            {isLargeScreen && (
                                <ul
                                    className={
                                        openNavbar
                                            ? 'm-nav__items -mobile'
                                            : 'm-nav__items'
                                    }
                                >
                                    <li
                                        className={`m-nav__items--item -link  ${
                                            isActive(ROUTE.ABOUT_CAMPAIGN.path)
                                                ? '-active'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to={`/${ROUTE.ABOUT_CAMPAIGN.path}/${projectId}/${encodedProjectName}${securityCodeParam}`}
                                        >
                                            {t('links.header.about')}
                                        </Link>
                                    </li>
                                    <li
                                        className={`m-nav__items--item -link  ${
                                            isActive(
                                                ROUTE.APPROVED_APPLICATIONS.path
                                            )
                                                ? '-active'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to={`/${ROUTE.APPROVED_APPLICATIONS.path}/${projectId}/${encodedProjectName}${securityCodeParam}`}
                                        >
                                            {t('links.header.approved')}
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
